<template>
  <div class="container-fluid container-courses">
    <div class="container-courses__search pb-4">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-between">
            <div class="col-sm-6 col-md-6 col-lg-6 col-lg-6 col-xl-6">
              <form class="filter-flex">
                <i class="fas fa-search fa-flip-horizontal"></i>
                <input
                  v-model="searchValue"
                  aria-label="Search"
                  class="form-control inputFilter md-filter-input me-2"
                  placeholder="Buscar clase por nombre"
                  type="text"
                  @keydown="searchClasses"
                />

                <div class="md-layout md-gutter">
                  <div class="md-layout-item" style="padding: 0 !important">
                    <md-field class="sort-box m-0">
                      <md-select
                        v-model="sortType"
                        class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                      >
                        <md-option
hidden
selected
value=""
                          >Filtrar por
                        </md-option>
                        <md-option value="DESC">Nuevo</md-option>
                        <md-option value="ASC">Antiguo</md-option>
                        <md-option value="ASC_name">A-Z</md-option>
                        <md-option value="DESC_name">Z-A</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-lg-4 col-xl-4">
              <div>
                <button
                  class="btn btn-course float-right"
                  @click="openClassesDialog"
                >
                  + NUEVA CLASE
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 container-courses__courses course-video-list">
          <template v-if="isLoadingClasses">
            <div class="col-12 card p-3">
              <div class="videos">
                <div
                  v-for="index in 8"
                  :key="index"
                  class="d-flex flex-column justify-content-between"
                >
                  <skeleton-admin-course />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <template v-if="getAllClasses.items && getAllClasses.items.length">
              <div class="col-12 card">
                <div class="videos">
                  <div
                    v-for="(_class, index) in getAllClasses.items"
                    :key="index"
                    class="p-3 border-card d-flex flex-column justify-content-between"
                  >
                    <div>
                      <div class="container-img w-100 position-relative">
                        <router-link
                          :to="'/admin/clases/clase/' + _class.id"
                          style="text-decoration: none"
                        >
                          <img
                            v-if="_class.poster && _class.poster.url"
                            :src="_class.poster.url"
                            alt="thumbnail"
                            class="borderless h-12 img-object-fit w-100"
                          />
                          <img
                            v-else
                            alt="imgmaquillate"
                            class="borderless h-12 img-object-fit w-100"
                            src="@/assets/images/logo/placeholder.png"
                          />
                        </router-link>
                        <div class="position-absolute top-0 right-0 mr-2 mt-2">
                          <div @click="changeClassFeatured(_class)">
                            <i
                              v-if="_class.featured"
                              class="fas fa-star class-featured"
                            ></i>
                            <i v-else class="far fa-star class-featured"></i>
                          </div>
                        </div>
                      </div>
                      <div class="py-2">
                        <router-link
                          :to="'/admin/clases/clase/' + _class.id"
                          style="text-decoration: none"
                        >
                          <h3
                            class="fw-bold m-0 p-0"
                            style="text-transform: uppercase"
                          >
                            {{ _class.title }}
                          </h3>
                        </router-link>
                        <!--                                  <small class='mb-0 text-muted'>-->
                        <!--                                    {{ getInstructors(course.instructors) }}-->
                        <!--                                  </small>-->
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-7 pr-0">
                        <span
                          :class="
                            _class.status === 'PUBLISH'
                              ? 'bagde-publish'
                              : 'bagde-draft'
                          "
                          >{{
                            _class.status === 'PUBLISH'
                              ? 'Publicado'
                              : 'Borrador'
                          }}</span
                        >
                      </div>
                      <div class="col-5 pl-0">
                        <span class="float-right">
                          <md-menu
                            :md-offset-x="-127"
                            :md-offset-y="-20"
                            md-size="auto"
                          >
                            <a
                              :href="`/admin/clases/clase/preview/${_class.id}`"
                              target="_blank"
                              ><i class="fas fa-eye eye-course"></i
                            ></a>
                            <md-button md-menu-trigger>•••</md-button>

                            <md-menu-content>
                              <md-menu-item>
                                <router-link
                                  :to="`/admin/clases/clase/${_class.id}`"
                                  class="text-decoration-none text-000 pr-5"
                                >
                                  Editar
                                </router-link>
                              </md-menu-item>
                              <md-menu-item>
                                <span
                                  class="pr-5 cursor"
                                  href
                                  @click="duplicateClass(_class.id)"
                                >
                                  Duplicar
                                </span>
                              </md-menu-item>
                              <md-menu-item>
                                <span
                                  class="pr-5 cursor"
                                  href
                                  @click="openDeleteClassesDialog(_class)"
                                >
                                  Eliminar
                                </span>
                              </md-menu-item>
                            </md-menu-content>
                          </md-menu>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-12 text-center">No hay clases creadas</div>
            </template>
            <div
              v-if="totalPages > 1"
              class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
            >
              <base-pagination
                :value="page"
                class="pagination-style"
                :page-count="totalPages"
                @input="paginationChange"
              ></base-pagination>
            </div>
          </template>
        </div>
      </div>
    </div>
    <create-class-dialog
      :is-open="isOpenClassesDialog"
      @close-modal="closeClassesDialog"
      @success="refetchClasses"
    />

    <delete-dialog
      :is-open="isOpenDeleteClassesDialog"
      @deleted="deleteClass"
      @close-modal="closeDeleteClassDialog"
    />
  </div>
</template>

<script>
import SkeletonAdminCourse from '../../../Components/skeletons/SkeletonAdminCourse.vue';
import {
  DELETE_CLASS,
  DUPLICATE_CLASS,
  FETCH_CLASSES,
  UPDATE_CLASS_FEATURE,
} from '../../../../store/actions/classes';
import CreateClassDialog from '../Advertisement/components/CreateClassDialog.vue';
import { mapGetters } from 'vuex';
import BasePagination from '../../../../components/BasePagination';
import DeleteDialog from '../components/DeleteDialog';

export default {
  name: 'ClassesView',
  components: {
    SkeletonAdminCourse,
    CreateClassDialog,
    BasePagination,
    DeleteDialog,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      selectedClass: undefined,
      isLoadingClasses: true,
      searchValue: null,
      sortType: 'DESC',
      isOpenClassesDialog: false,
      isOpenDeleteClassesDialog: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getAllClasses']),
    totalPages() {
      return Math.ceil(this.getAllClasses.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchClasses({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  mounted() {
    this.fetchClasses();
  },
  methods: {
    searchClasses(e) {
      if (e.key === 'Enter') {
        this.fetchClasses();
      }
    },
    refetchClasses() {
      this.isLoadingClasses = true;
      this.fetchClasses();
    },
    changeClassFeatured(_class) {
      const featured = !_class.featured;

      this.$store
        .dispatch(UPDATE_CLASS_FEATURE, {
          id: _class.id,
          featured: !_class.featured,
        })
        .then(() => {
          if (featured) {
            this.notify('success', `Sea asigno ${_class.title} como destacado`);
          } else {
            this.notify(
              'success',
              `Sea removido ${_class.title} como destacado`,
            );
          }
          this.refetchClasses();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para asignar/remover la clase como destacado',
          );
        });
    },
    fetchClasses(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      this.$store
        .dispatch(FETCH_CLASSES, filter)
        .then(() => (this.isLoadingClasses = false));
    },
    openClassesDialog(selectedClass) {
      this.selectedClass = selectedClass;
      this.isOpenClassesDialog = true;
    },
    closeClassesDialog() {
      this.selectedClass = undefined;
      this.isOpenClassesDialog = false;
    },
    openDeleteClassesDialog(selectedClass) {
      this.selectedClass = selectedClass;
      this.isOpenDeleteClassesDialog = true;
    },
    closeDeleteClassDialog() {
      this.selectedClass = undefined;
      this.isOpenDeleteClassesDialog = false;
    },
    deleteClass() {
      console.log(this.selectedClass.id);
      this.$store
        .dispatch(DELETE_CLASS, this.selectedClass.id)
        .then(() => {
          this.notify('success', 'Se ha eliminado la clase con éxito');
          this.closeDeleteClassDialog();
          this.refetchClasses();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para eliminar la clase');
        });
    },
    duplicateClass(classId) {
      this.$store
        .dispatch(DUPLICATE_CLASS, classId)
        .then(() => {
          this.notify('success', 'Se ha duplicado la clase con éxito');
          this.refetchClasses();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para duplicar la clase');
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_CLASSES, {
        page: this.page,
        per_page: this.perPage,
        featured: 1,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.md-field .md-input:focus,
.md-field .md-textarea:focus,
.md-select-value,
.md-select-value:active {
  border: none !important;
}
.course-video-list {
  margin-top: 25px;
  padding: 0;
}

.class-featured {
  color: #f7d917;
  cursor: pointer;
}

.md-menu-content {
  border-radius: 5px !important;
  z-index: 10;
  max-height: none !important;
}
</style>
