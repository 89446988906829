<template>
  <md-dialog :md-active="open" class="modal-close">
    <span
class="material-icons close text-right"
@click="closeModal"
      >close</span
    >
    <div class="body-modal-course">
      <md-dialog-title>NUEVA CLASE</md-dialog-title>

      <md-dialog-content class="mt-2">
        <span>
          Ingrese el nombre de la clase que desea crear.</span
        ></md-dialog-content
      >

      <md-dialog-content>
        <md-field>
          <div class="md-field md-theme-default md-has-placeholder pt-0">
            <md-input v-model="value" placeholder="NUEVA CLASE"></md-input>
          </div>
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-button md-primary md-theme-default"
          @click="closeModal"
          >CANCELAR
        </md-button>
        <base-button
          :loading="isLoading"
          class="md-primary w-100"
          @click="createClass"
          >AGREGAR
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import { CREATE_CLASS } from '../../../../../store/actions/classes';
import { BaseButton } from '../../../../../components';
export default {
  name: 'CreateCourseDialog',
  components: {
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      isLoading: false,
      value: '',
    };
  },

  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  mounted() {
    this.value = '';
  },
  methods: {
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    reset() {
      this.value = '';
    },
    createClass() {
      this.isLoading = true;
      this.$store
        .dispatch(CREATE_CLASS, { title: this.value })
        .then(() => {
          this.isLoading = false;
          this.notify('success', 'Se ha creado la clase correctaemnte');
          this.$emit('success');
          this.closeModal();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para crear la clase');
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style scoped></style>
